/*
 * @Descripttion: 折线图
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-01 16:22:42
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-05-22 16:37:00
 */
import * as echarts from 'echarts'

// 跟进数
function followLineEcharts(el,data) {
    var option = {
        legend: {
          top: '5%',
          left: 'center'
        },
        grid: {
            x: 80,
            y: 60,
            x2: 20,
            y2: 60,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: data.categories
        },
        yAxis: {
          type: 'value'
        },
        color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
        series: [
          {
            name: data.series[0].name,
            data: data.series[0].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          },{
            name: data.series[1].name,
            data: data.series[1].data,
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top'
            },
          }
        ]
      };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}

// 3d全景
function panoramaLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 60,
          x2: 20,
          y2: 60,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}
// 海报分享数
function posterLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 60,
          x2: 20,
          y2: 60,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 门店数
function shopLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 曝光获客数
function exposureLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[3].name,
          data: data.series[3].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 活动
function activityLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[3].name,
          data: data.series[3].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 品牌跟进
function followMtLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 40,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 全景趋势
function sceneLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 40,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[3].name,
          data: data.series[3].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}
  
// 功能使用图示
function summaryEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 40,
          y: 60,
          x2: 20,
          y2: 30,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'bar',
          barGap: '0%', 
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series[3].name,
          data: data.series[3].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

// 五大功能使用图示
function oftenEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 40,
          y: 80,
          x2: 20,
          y2: 30,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[1].name,
          data: data.series[1].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series[2].name,
          data: data.series[2].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series[3].name,
          data: data.series[3].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series[4].name,
          data: data.series[4].data,
          type: 'line',
          barGap: '0%', 
          areaStyle: {
            opacity: 0
          },
          label: {
            show: true,
            position: 'top'
          },
        },
        
        {
          name: data.series1[0].name,
          data: data.series1[0].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series1[1].name,
          data: data.series1[1].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
        },
        {
          name: data.series1[2].name,
          data: data.series1[2].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series1[3].name,
          data: data.series1[3].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
        },{
          name: data.series1[4].name,
          data: data.series1[4].data,
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
        }
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}


// 运营功能使用图示
function operationEcharts(el,data) {
  // 循坏赋值数据
  var series=[];    //定义一个数组变量用于存放配置
  for(var i = 0;i<data.series.length;i++){
      series.push({
          name: data.series[i].name,
          type: 'bar',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },       
          data: data.series[i].data
      });
  }
  var option = {
      legend: {
        top: '5%',
        left: 'center',
        tooltip: {
          show: true,
          trigger: 'item',
          extraCssText:'width:260px; white-space:pre-wrap',
          formatter:function (params) {
            let content = ''
            switch (params.name) {
              case '客户管理':
                content = data.series[0].content
              break;
              case '全景设计':
                content = data.series[1].content
              break;
              case '推广':
                content = data.series[2].content
              break;
              case '营销活动':
                content = data.series[3].content
              break;
              case '门店任务':
                content = data.series[4].content
              break;
              case '共享圈':
                content = data.series[5].content
              break;
              case '口碑':
                content = data.series[6].content
              break;
              case '快速报价':
                content = data.series[7].content
              break;
              case '目标管理':
                content = data.series[8].content
              break;
              default:
                break;
            }
            return content
          }
        },      
      },
      grid: {
          x: 40,
          y: 80,
          x2: 20,
          y2: 30,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ffdcb4','#68e070','#d7dbca','#fd433a','#703dff'],
      series: series
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}
function functionDataLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 50,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
        {
          name: data.series[0].name,
          data: data.series[0].data,
          type: 'line',
          areaStyle: {},
          label: {
            show: true,
            position: 'top'
          },
        },
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}
function revenueLineEcharts(el,data) {
  var option = {
      legend: {
        top: '5%',
        left: 'center'
      },
      grid: {
          x: 80,
          y: 50,
          x2: 20,
          y2: 40,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: data.categories
      },
      yAxis: {
        type: 'value'
      },
      color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#40e0d0','#ec742c'],
      series: [
          {
              name: data.series[0].name,
              data: data.series[0].data,
              type: 'line',
              areaStyle: {},
              label: {
                  show: true,
                  position: 'top'
              },
          },
          {
              name: data.series[1].name,
              data: data.series[1].data,
              type: 'line',
              areaStyle: {},
              label: {
                  show: true,
                  position: 'top'
              },
          },
      ]
    };
  let myChart = echarts.init(document.getElementById(el));
  myChart.setOption(option)
}

export {
    followLineEcharts,
    posterLineEcharts,
    shopLineEcharts,
    exposureLineEcharts,
    activityLineEcharts,
    followMtLineEcharts,
    sceneLineEcharts,
    summaryEcharts,
    oftenEcharts,
    operationEcharts,
    functionDataLineEcharts,
    revenueLineEcharts,
    panoramaLineEcharts
}
    