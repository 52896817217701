<!--
 * @Descripttion: 海报数据报表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-04 10:56:14
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-04 15:45:14
-->
<template>
    <div class="posterDataReport">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">海报数据报表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <commonSelect
                    @changeDate="changeDate"
                    @clickTagDate="clickTagDate"
                ></commonSelect>
            </div>
        </div>
        <div class="case-wrap data-collect" v-loading="loading1">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">数据汇总</div>
            </div>
            <div class="content-wrap">
                <div class="item">
                    <span>{{basicData.total}}</span>海报总数
                </div>
                <div class="item">
                    <span>{{basicData.propaganda}}</span>日常宣传
                </div>
                <div class="item">
                    <span>{{basicData.festival}}</span>节日问候
                </div>
                <div class="item">
                    <span>{{basicData.society}}</span>社会热点
                </div>
                <div class="item">
                    <span>{{basicData.share_total}}</span>分享总量
                </div>
            </div>
        </div>
        <div class="case-wrap share-collect" v-loading="loading2">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">海报分享</div>
            </div>
            <div class="share-wrap">
                <div id="posterGrades" style="width: 100%;height: 390px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {posterLineEcharts} from "@/echarts/lineEchartData";
export default {
    components: {
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'posterDataReport',
            dateType: '',
            startDate: '',
            endDate: '',
            basicData: {},
            loading1: false,
            loading2: false,
        }
    },
    mounted () {
        this.getDataReport();
        this.getPosterLineChart();
    },
    methods: {
        // 获取海报分享数据
        getPosterLineChart(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect("/customerservicev1/data_report/posterShareDataReportLineChart",params,(res)=>{
                posterLineEcharts('posterGrades',res.data);
                this.loading2 = false;
            });
        },
        // 获取基本数据
        getDataReport(){
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading1 = true;
            common.connect("/customerservicev1/data_report/posterDataReport",params,(res)=>{
                this.basicData = res.data;
                this.loading1 = false;
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getDataReport();
            this.getPosterLineChart();
        },
        clickTagDate(item){
            this.dateType = item.label;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getDataReport();
                this.getPosterLineChart();
            }
        },
    },
}
</script>

<style lang='scss'>
.posterDataReport{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
    .data-collect{
        .content-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            .item{
                border: 1px solid #ddd;
                border-radius: 6px;
                width: 18%;
                display: flex;
                justify-content: center;
                align-items: baseline;
                height: 120px;
                line-height: 120px;
                span{
                    font-size: 26px;
                    font-weight: bold;
                    color: #000000;
                    margin-right: 10px;
                }
            }
        }
    }
}
</style>
